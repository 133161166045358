// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "bootstrap"
import 'select2'
import 'select2/dist/css/select2.css'
global.jQuery = global.$ = require('jquery');

Rails.start()
Turbolinks.start()
ActiveStorage.start()

function readImageURL(input) {
  if (input.files && input.files[0]) {
      var reader = new FileReader();            
    reader.onload = function (e) {
        $(document.getElementById(input.id + "_medium")).attr('src', e.target.result);
      }
      reader.readAsDataURL(input.files[0]);
  }
}

$(document).on('turbolinks:load', function() {
  $('.select2').select2()
  $(".select2").on("select2:select", function (evt) {
    var element = evt.params.data.element;
    var $element = $(element);

    if ($(this).find(":selected").length > 1) {
      var $second = $(this).find(":selected").eq(-1);
      $second.after($element);
    } else {
      $element.detach();
      $(this).prepend($element);
    }

    $(this).trigger("change");
  });
  
  $('.with-tip').tooltip({ html: true, trigger: 'hover' })
  $('.with-tip').on('click', function () {
    $(this).tooltip('hide')
  })
  $('.autofadeout').delay(5000).slideToggle()
  $(".image_upload").on('change', function(){
    readImageURL(this)
  })
})

$(document).on("turbolinks:before-cache", function() {
  $('.select2').each( function() {
      if($(this).data('select2')) {
          $(this).select2('destroy');
      }
  })
  $('[data-toggle="tooltip"]').tooltip('hide')
});